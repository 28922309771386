#map-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 0;
}
#map-container .composer {
  background: rgba(0,0,0,0.9);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  padding: 20px;
  transition: opacity 1s ease;
}
#map-container .composer h2 {
  color: #fefefe;
  margin-bottom: 30px;
  font-size: 30px;
}
#map-container .composer.open {
  z-index: 99;
  opacity: 1;
}
#map-container .composer.open .input {
  position: relative;
  top: 20px;
}
#map-container .composer.open button.primary {
  margin-top: 70px;
}
#map-container .selected-user {
  background: rgba(0,0,0,0.7);
  position: fixed;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 0;
  opacity: 0;
  padding: 20px;
  transition: opacity 1s ease;
}
#map-container .selected-user .fake-link {
  margin-top: 5px;
}
#map-container .selected-user .team-avatar {
  width: 80px;
  float: left;
  margin-right: 20px;
}
#map-container .selected-user * {
  color: #fff;
}
#map-container .selected-user h1,
#map-container .selected-user h2,
#map-container .selected-user h3,
#map-container .selected-user h4,
#map-container .selected-user h5 {
  font-weight: 400;
  margin: 0;
  padding: 0;
}
#map-container .selected-user h1 {
  font-size: 24px;
  font-weight: 100;
}
#map-container .selected-user h2 {
  color: #e3c9de;
  font-weight: 700;
  font-size: 16px;
}
#map-container .selected-user .stats {
  font-size: 12px;
  padding-left: 10px;
  color: rgba(255,255,255,0.6);
}
#map-container .selected-user .stats h3 {
  color: rgba(255,255,255,0.6);
  display: inline-block;
  margin-right: 10px;
}
#map-container .selected-user.open {
  opacity: 1;
  z-index: 9;
}
