.overlay {
  background: rgba(0,0,0,0);
  height: 100%;
  position: fixed;
  top: 0px;
  transition: ease all 0.5s;
  width: 100%;
  z-index: -1;
  display: none;
}
.overlay.visible {
  background: rgba(0,0,0,0.7);
  display: block;
  z-index: 2;
}
