.error-container {
  text-align: center;
  display: block;
  background: #c00;
  height: 100%;
  position: absolute;
  top: 0px;
  padding: 20px 20px;
  background: linear-gradient(227deg, #140101, #e04c4c);
  background-size: 400% 400%;
  transition: ease opacity 1s;
  -webkit-animation: BackgroundGradient 20s ease infinite;
  -moz-animation: BackgroundGradient 20s ease infinite;
  animation: BackgroundGradient 20s ease infinite;
}
@-webkit-keyframes BackgroundGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@-moz-keyframes BackgroundGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
.error-container img {
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
}
.error-container h3,
.error-container h5 {
  color: #fff;
  margin: 0 0 10px 0;
}
.error-container a {
  color: #ff0;
  display: block;
}
@-moz-keyframes BackgroundGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@-webkit-keyframes BackgroundGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@-o-keyframes BackgroundGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@keyframes BackgroundGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
