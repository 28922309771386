label {
  display: block;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
  transition: all 0.25s ease;
}
.input {
  margin-top: -5px;
  margin-bottom: 10px;
}
.input.bold input {
  font-weight: 700;
}
.input.floating-label.active label {
  top: 0px;
  left: 0px;
  color: #231f20;
}
.input.floating-label.active.light label {
  color: #fefefe;
}
.input.floating-label label {
  position: relative;
  height: 30px;
  left: 20px;
  margin-bottom: 0px;
  top: 50px;
  cursor: initial;
  color: #231f20;
}
.input.floating-label.light label {
  color: #231f20;
}
.input input {
  display: block;
  padding: 20px 25px;
  font-size: 17px;
  color: #231f20;
  border: 0;
  width: 100%;
}
.input.light label {
  color: #fefefe;
}
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}
select option {
  padding: 10px;
}
radio-group {
  display: inline-block;
  clear: both;
  cursor: pointer;
  margin-right: 10px;
}
radio-group.block {
  display: block;
  margin-bottom: 10px;
}
radio-group.checked radio-button {
  background: #fefefe;
}
radio-group.checked radio-button:before {
  border-radius: 50px;
  content: '';
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  left: 8px;
  top: 8px;
  background: #16489b;
}
radio-group radio-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: #fefefe;
  border-radius: 50px;
  float: left;
  border: solid 2px #000;
}
radio-group radio-button:before {
  transition: all 0.25s ease;
  border-radius: 50px;
  content: '';
  display: block;
  position: relative;
  height: 5px;
  width: 5px;
  left: 20px;
  top: 20px;
  background: 0;
}
radio-group radio-label {
  color: #231f20;
  display: inline-block;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
}
radio-group.light radio-label {
  color: #fefefe;
}
radio-group.light.checked radio-button:before {
  background: #16489b;
}
radio-group.dark radio-label {
  color: #16489b;
}
radio-group.dark.checked radio-button:before {
  background: #16489b;
}
button {
  background-color: #f2f1ef;
  color: #231f20;
  cursor: pointer;
  border: 0;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 5px;
  padding: 15px 40px;
  transition: background 0.5s ease;
}
button[disabled] {
  opacity: 0.5;
}
button[disabled]:hover {
  background-color: #f2f1ef;
}
button.block {
  display: block;
  width: 100%;
}
button.accent {
  background-color: #f8eb00;
  color: #231f20;
}
button.primary {
  background-color: #e3c9de;
  color: #231f20;
}
button.secondary {
  background-color: #16489b;
  color: #fefefe;
}
button.ripple-effect:active,
button.ripple-effect:hover {
  background-color: #e3c9de;
}
button:active,
button:hover {
  background-color: #e3c9de;
}
