.profile .profile-btn {
  display: block;
  margin: 10px 0;
  background: 0;
  border: 0;
  border: solid 2px #ccc;
  color: #84929d;
  padding: 10px 20px;
}
.profile .profile-btn:focus {
  outline: none;
}
.profile .profile-btn.assigned {
  border: solid 2px #c250d9;
  color: #c250d9;
}
