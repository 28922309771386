.quest-list .team-name {
  text-align: center;
  font-size: 28px;
  margin: 40px 0 10px;
}
.quest-list .hero {
  width: 100%;
  height: 200px;
  padding: 20px;
}
.quest-list .team-stats {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
}
.quest-list .team-stats .solved {
  display: inline-block;
  margin-right: 10px;
}
.quest-list .card {
  margin-bottom: 20px;
  transition: ease all 0.5s;
  cursor: pointer;
}
.quest-list .card:active {
  filter: blur(2px);
}
.quest-list .card .title {
  background: #fff;
  padding: 10px;
}
.quest-list .card .description {
  color: #fff;
  background: rgba(0,0,0,0.8);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1rem;
}
