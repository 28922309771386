.login-page {
  background: #16489b;
  z-index: 1;
}
.login-page .email-domain {
  display: inline-block;
  font-size: 1.1rem;
}
.login-page #email-input {
  background: #fff;
  padding: 27px;
  border: 1px solid #000;
  width: 300px;
}
