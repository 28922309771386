admin-players {
  display: block;
}
admin-players .avatar {
  width: 200px;
}
admin-players .tiny-avatar {
  border-radius: 50px;
  width: 40px;
  float: left;
}
admin-players .department {
  color: rgba(0,0,0,0.4);
  font-size: 12px;
  float: right;
  padding-top: 5px;
}
admin-players .user-item {
  margin-bottom: 20px;
  clear: both;
}
admin-players .user-name {
  display: inline-block;
  padding: 7px 10px 0px 20px;
}
admin-players .list {
  padding: 0;
}
admin-players .list-item {
  padding: 20px;
}
admin-players h3 {
  margin-bottom: 0;
}
