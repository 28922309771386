.quest-card {
  background: #fefefe;
  border: solid 1px #231f20;
  margin-bottom: 20px;
  text-align: center;
}
.quest-card .clue-photo {
  width: 100%;
}
.quest-card .solved-icon,
.quest-card .lock-icon,
.quest-card .gaveup-icon {
  display: inline-block;
  color: #231f20;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
}
.quest-card .quest-header {
  color: #231f20;
  cursor: pointer;
  padding: 5px;
  transition: background 0.5s ease;
}
.quest-card .quest-header h2 {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  width: calc(100% - 50px);
}
.quest-card .attempts {
  color: rgba(0,0,0,0.5);
  margin: 5px 0 0;
}
.quest-card.unlocked .quest-header {
  background: #e3c9de;
}
.quest-card.locked .quest-header {
  cursor: not-allowed;
  transition: all 0.5s ease;
}
.quest-card.locked .quest-header:active {
  opacity: 0.2;
}
.quest-card .quest-wrapper {
  overflow: hidden;
  border-top: solid 1px #231f20;
}
.quest-card .quest-wrapper .quest-content {
  padding: 20px;
}
.quest-card .quest-wrapper .hint {
  font-size: 25px;
  font-weight: 700;
}
.quest-card .quest-wrapper .hint .clue-photo {
  padding: 30px 0;
}
.quest-card .quest-wrapper .point-action {
  float: right;
  font-size: 16px;
  font-weight: 400;
}
.quest-card.closed .quest-wrapper {
  max-height: 0;
  opacity: 0;
  transition: all 0.75s ease-out;
  margin-top: -1px;
}
.quest-card.solved .quest-header {
  background: #e3c9de;
}
.quest-card.active .quest-header {
  background: #f8eb00;
}
.quest-card .quest-next,
.quest-card .quest-failed,
.quest-card .happy-hour {
  opacity: 0;
  max-height: 0;
  padding: 0;
  overflow: hidden;
  transition: opacity 0.75s ease;
}
.quest-card.open .quest-header {
  background: #f8eb00;
}
.quest-card.open .quest-wrapper {
  max-height: 1500px;
  opacity: 1;
  transition: all 0.5s ease-in;
}
.quest-card.open .quest-wrapper .quest-content {
  max-height: 1500px;
  transition: opacity 0.75s ease;
}
.quest-card.open .quest-wrapper .quest-content h2 {
  font-weight: 600;
}
.quest-card.open .quest-wrapper.failed .quest-content {
  opacity: 0;
  max-height: 0;
  transition: opacity 0.75s ease;
  overflow: hidden;
}
.quest-card.open .quest-wrapper.failed .quest-failed {
  font-failed: 600;
  opacity: 1;
  max-height: 1500px;
  transition: opacity 0.75s ease;
  padding: 40px 20px;
}
.quest-card.open .quest-wrapper.failed .quest-failed h1 {
  font-size: 28px;
  margin: 0 0 20px 0;
}
.quest-card.open .quest-wrapper.failed .quest-failed h2 {
  font-size: 18px;
  margin: 20px 0;
}
.quest-card.open .quest-wrapper.failed .quest-failed .unlock {
  background: #e3c9de;
  text-align: center;
  text-transform: uppercase;
}
.quest-card.open .quest-wrapper.congrats .quest-content {
  opacity: 0;
  max-height: 0;
  transition: opacity 0.75s ease;
  overflow: hidden;
}
.quest-card.open .quest-wrapper.congrats .quest-next {
  font-weight: 600;
  opacity: 1;
  max-height: 1500px;
  transition: opacity 0.75s ease;
  padding: 40px 20px;
}
.quest-card.open .quest-wrapper.congrats .quest-next h1 {
  font-size: 28px;
  margin: 0 0 20px 0;
}
.quest-card.open .quest-wrapper.congrats .quest-next h2 {
  font-size: 18px;
  margin: 20px 0;
}
.quest-card.open .quest-wrapper.congrats .quest-next .unlock {
  background: #e3c9de;
  text-align: center;
}
.quest-card.open .quest-wrapper.reveal-hh .quest-content {
  opacity: 0;
  max-height: 0;
  transition: opacity 0.75s ease;
  overflow: hidden;
}
.quest-card.open .quest-wrapper.reveal-hh .happy-hour {
  font-weight: 600;
  opacity: 1;
  max-height: 1500px;
  transition: opacity 0.75s ease;
  padding: 40px 20px;
}
.quest-card.open .quest-wrapper.reveal-hh .happy-hour h1 {
  font-size: 28px;
  margin: 0 0 20px 0;
}
.quest-card.open .quest-wrapper.reveal-hh .happy-hour h2 {
  font-size: 18px;
  margin: 20px 0;
}
.quest-card.open .quest-wrapper.reveal-hh .happy-hour img {
  width: 100%;
}
.quest-card.open .quest-wrapper .hint {
  font-size: 25px;
  font-weight: 600;
  margin: 20px 0 40px;
}
.quest-card.open .quest-wrapper .hint-1.used {
  opacity: 0.5;
}
.quest-card.open .quest-wrapper .hint-2 {
  margin: 20px 0 40px 0;
}
.quest-card.open .quest-wrapper .point-action {
  float: right;
  font-size: 16px;
  font-weight: 400;
}
.quest-card .button-icon {
  float: left;
  margin-right: 10px;
}
.quest-card .button-label {
  text-transform: uppercase;
}
.quest-card .button-icon {
  width: 29px;
  height: 25px;
}
.quest-card .found-it,
.quest-card .give-up,
.quest-card .buy-hint {
  border-radius: 100;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 10px;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
}
