* {
  box-sizing: border-box;
  outline: 0;
}
body,
html {
  color: #231f20;
  background: #f2f1ef !important;
  font-family: "Open Sans", Roboto, Helvetica, Arial;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
#app {
  opacity: 0;
  transition: ease 0.5s opacity;
}
#app.visible {
  opacity: 1;
}
.hidden {
  display: none;
}
.content {
  position: absolute;
  top: 30px;
  padding: 20px 20px;
  width: 100%;
  z-index: 1;
}
.content.prelaunch {
  top: 0;
}
.content.flex-center {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.invisible {
  opacity: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #231f20;
  font-weight: 400;
  font-family: "Open Sans", Roboto, Helvetica, Arial;
}
h1.primary,
h2.primary,
h3.primary,
h4.primary,
h5.primary,
h6.primary {
  color: #e3c9de;
}
h1.secondary,
h2.secondary,
h3.secondary,
h4.secondary,
h5.secondary,
h6.secondary {
  color: #16489b;
}
h1.accent,
h2.accent,
h3.accent,
h4.accent,
h5.accent,
h6.accent {
  color: #f8eb00;
}
h1 {
  font-size: 20px;
  font-weight: bold;
}
h1.rainbow,
h2.rainbow,
h3.rainbow,
h4.rainbow,
h5.rainbow,
h6.rainbow {
  background: linear-gradient(to right, #f8eb00, #16489b 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.close-icon {
  color: #231f20;
  position: absolute;
  right: 20px;
  top: 25px;
}
.form-group {
  margin: 20px 0;
}
.form-group .toggle {
  max-width: 600px;
}
.form-error {
  margin-top: 20px;
  color: #f00;
}
.preview {
  max-width: 600px;
}
.preview > img {
  width: 100%;
}
#email-input {
  background: #fff;
  padding: 27px;
  border: 1px solid #000;
  width: 300px;
}
.submit-section {
  z-index: 1;
}
.submit-section .circle-btn {
  position: relative;
  width: 131px;
  height: 131px;
  background: none;
  padding: 0;
  margin: 0;
}
.submit-section .circle-btn.red > span {
  background-color: #ef3705;
  text-transform: uppercase;
}
.submit-section .circle-btn.red:before {
  background-color: #ef3705;
}
.submit-section .circle-btn > span {
  width: 131px;
  height: 131px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  padding-top: 40px;
  background-color: #f8eb00;
  border: solid 1px #000;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.25;
  transition: ease 0.075s all;
  cursor: pointer;
  outline: 0;
  padding: 40px 20px;
}
.submit-section .circle-btn:active > span {
  animation: 0.5s ease-out alternate button-press-top;
}
.submit-section .circle-btn:active:before {
  animation: 0.5s ease-out alternate button-press-bottom;
}
.submit-section .circle-btn:before {
  display: block;
  z-index: -1;
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background-color: #f8eb00;
  border: solid 1px #000;
}
.submit-section .form-error {
  position: absolute;
}
@-moz-keyframes button-press-bottom {
  from {
    background-color: #f8eb00;
  }
  to {
    background-color: #333;
  }
}
@-webkit-keyframes button-press-bottom {
  from {
    background-color: #f8eb00;
  }
  to {
    background-color: #333;
  }
}
@-o-keyframes button-press-bottom {
  from {
    background-color: #f8eb00;
  }
  to {
    background-color: #333;
  }
}
@keyframes button-press-bottom {
  from {
    background-color: #f8eb00;
  }
  to {
    background-color: #333;
  }
}
@-moz-keyframes button-press-top {
  from {
    top: 0px;
  }
  to {
    top: 10px;
  }
}
@-webkit-keyframes button-press-top {
  from {
    top: 0px;
  }
  to {
    top: 10px;
  }
}
@-o-keyframes button-press-top {
  from {
    top: 0px;
  }
  to {
    top: 10px;
  }
}
@keyframes button-press-top {
  from {
    top: 0px;
  }
  to {
    top: 10px;
  }
}
a {
  color: #16489b;
  text-decoration: none;
  transition: ease 0.5s all;
}
a.animate:hover {
  text-decoration: none;
  background: linear-gradient(227deg, #a6e16d, #3f70c1);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-animation: MenuGradient 4s ease infinite;
  -moz-animation: MenuGradient 4s ease infinite;
  animation: MenuGradient 4s ease infinite;
}
@-webkit-keyframes MenuGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@-moz-keyframes MenuGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@-moz-keyframes MenuGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@-webkit-keyframes MenuGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@-o-keyframes MenuGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@keyframes MenuGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
.fake-link {
  color: #16489b;
  cursor: pointer;
  text-decoration: none;
  transition: ease 0.5s all;
}
.fake-link:hover {
  color: #f8eb00;
}
.fake-link.animate:hover {
  text-decoration: none;
  background: linear-gradient(227deg, #a6e16d, #3f70c1);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-animation: MenuGradient 4s ease infinite;
  -moz-animation: MenuGradient 4s ease infinite;
  animation: MenuGradient 4s ease infinite;
}
@-webkit-keyframes MenuGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@-moz-keyframes MenuGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@-moz-keyframes MenuGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@-webkit-keyframes MenuGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@-o-keyframes MenuGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
@keyframes MenuGradient {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}
.add-team .hero-image,
.edit-team .hero-image,
.view-team .hero-image {
  width: 100%;
  max-width: 400px;
}
.add-team .toggle,
.edit-team .toggle,
.view-team .toggle {
  clear: both;
}
.location-button-wrapper {
  margin-top: 20px;
}
