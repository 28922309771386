.avatar {
  width: 200px;
}
.tiny-avatar {
  width: 40px;
  float: left;
}
.user-item {
  margin-bottom: 20px;
  clear: both;
}
.user-name {
  display: inline-block;
  padding: 7px 10px 0px 20px;
}
