admin-dashboard {
  display: block;
  margin-top: 10px;
}
admin-dashboard h1,
admin-dashboard h2,
admin-dashboard h3,
admin-dashboard h4,
admin-dashboard h5 {
  font-weight: 400;
  margin: 0;
  padding: 0;
}
admin-dashboard .team-container {
  background: #fff;
  margin: 20px 0;
  padding: 20px;
  clear: both;
  min-height: 120px;
}
admin-dashboard .team-container .stats {
  font-size: 12px;
  padding-left: 10px;
  color: rgba(0,0,0,0.6);
}
admin-dashboard .team-container .stats h3 {
  color: rgba(0,0,0,0.6);
  display: block;
  margin-right: 10px;
}
admin-dashboard .team-container .stats h2 {
  color: #16489b;
  font-size: 16px;
  display: block;
  margin-right: 10px;
  margin-bottom: 10px;
}
admin-dashboard .team-container .team-avatar {
  float: left;
  width: 80px;
  margin-right: 20px;
  margin-bottom: 90px;
}
admin-dashboard .team-container h1 {
  font-weight: 400;
  font-size: 22px;
  margin: 0;
}
admin-dashboard .team-container h4 {
  color: rgba(0,0,0,0.5);
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}
