toast {
  background: #fefefe;
  display: block;
  opacity: 0;
  position: fixed;
  width: 100%;
  padding: 10px;
  z-index: 0;
  transition: ease all 0.5s;
}
toast h1 {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
toast.top {
  top: -500px;
}
toast.bottom {
  bottom: -500px;
}
toast.visible {
  opacity: 1;
  z-index: 3;
}
toast.visible.top {
  top: 0;
}
toast.visible.bottom {
  bottom: 0;
}
toast.dark,
toast.dark-center {
  background: #231f20;
}
toast.dark *,
toast.dark-center * {
  color: #fefefe;
}
toast.info,
toast.info-center {
  background: #e3c9de;
}
toast.info *,
toast.info-center * {
  color: #231f20;
}
toast.good,
toast.good-center {
  background: #83b92c;
}
toast.good *,
toast.good-center * {
  color: #fefefe;
}
toast.bad-center,
toast.good-center,
toast.info-center,
toast.dark-center {
  text-align: center;
}
toast.bad,
toast.bad-center {
  background: #da3a0f;
}
toast.bad *,
toast.bad-center * {
  color: #fefefe;
}
