.view-team.content {
  background: #000;
  padding: 20px 0 0;
  text-align: center;
}
.view-team.content .hero-image {
  width: 218px;
  margin: 38px;
}
.view-team.content .team-list {
  background: #e3c9de;
  padding: 22px 12px;
  text-align: center;
}
.view-team.content .team-list h1 {
  margin: 0;
}
.view-team.content .team-list .players {
  list-style: none;
  padding: 0;
  margin: 30px 0;
}
.view-team.content .team-list .players .player {
  background: #fff;
  border: 1px solid #000;
  border-bottom: 0;
  padding: 15px;
}
.view-team.content .team-list .players .player:last-child {
  border-bottom: 1px solid #000;
}
.view-team.content .disrupt {
  background: #fff;
  padding: 30px;
  text-align: center;
}
.view-team.content .disrupt h2 {
  font-size: 18px;
  font-weight: bold;
}
.view-team.content .disrupt p {
  font-size: 18px;
}
.view-team.content .leave-team,
.view-team.content .disrupt-team {
  padding: 30px;
  text-align: center;
  color: #fff;
}
.view-team.content .leave-team .bye-felicia,
.view-team.content .disrupt-team .bye-felicia {
  background: #f8eb00;
  text-transform: uppercase;
}
.view-team.content .leave-team .count,
.view-team.content .disrupt-team .count {
  opacity: 0.5;
}
