.countdown {
  background: #e3c9de;
  text-align: center;
  padding: 0;
  min-height: 100vh;
}
.countdown .paper {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 485px;
  top: 10px;
}
.countdown .paper-pad {
  position: absolute;
  top: 0;
  z-index: -1;
  left: calc(50% - 145px);
}
.countdown .paper-pad .back-page {
  animation: 2s up-down ease infinite alternate;
}
.countdown .paper-content {
  width: 200px;
  position: absolute;
  height: 432px;
  margin: 0;
  left: calc(50% - 100px);
  padding-top: 170px;
}
.countdown .paper-content > span {
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin-bottom: 22px;
}
.countdown .paper-content > span.countdown-numbers {
  font-size: 30px;
  font-weight: bold;
}
.countdown .paper-content > span:last-child {
  margin-bottom: 0;
}
.countdown .cta {
  width: 100%;
  background: #fff;
  border-top: 1px solid #000;
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.countdown .cta > p {
  margin-top: 0;
}
.countdown .cta .calibrate-location {
  margin-top: 20px;
}
.countdown .cta .calibrate-location button.found-it {
  background: #e3c9de;
}
.countdown .cta .rules-btn {
  font-size: 18px;
  font-weight: bold;
  text-transform: none;
  padding: 0;
  margin: 0;
  position: relative;
  padding-right: 70px;
  color: #000;
}
.countdown .cta .rules-btn .arrow {
  position: absolute;
  top: -7px;
  right: 0;
  animation: 0.5s back-forth ease-out infinite alternate;
}
@-moz-keyframes up-down {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-5px);
  }
}
@-webkit-keyframes up-down {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-5px);
  }
}
@-o-keyframes up-down {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-5px);
  }
}
@keyframes up-down {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-5px);
  }
}
@-moz-keyframes back-forth {
  from {
    right: 0;
  }
  to {
    right: 5px;
  }
}
@-webkit-keyframes back-forth {
  from {
    right: 0;
  }
  to {
    right: 5px;
  }
}
@-o-keyframes back-forth {
  from {
    right: 0;
  }
  to {
    right: 5px;
  }
}
@keyframes back-forth {
  from {
    right: 0;
  }
  to {
    right: 5px;
  }
}
