.rules {
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
}
.rules .heading {
  padding: 80px 40px 40px 40px;
  border-bottom: 1px solid #000;
  background: #e3c9de;
}
.rules .heading h1 {
  display: inline-block;
  width: calc(100% - 130px);
  margin: 0 0 0 50px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.rules .rules-list {
  list-style: none;
  max-width: 375px;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.rules .rules-list li {
  position: relative;
  border-bottom: 1px solid #000;
  text-align: left;
  padding-left: 112px;
  padding: 50px 25px 50px 112px;
}
.rules .rules-list li:before {
  display: block;
  content: '';
  width: 60px;
  height: 60px;
  border: 1px solid #000;
  border-radius: 50px;
  background: #fff;
  position: absolute;
  left: 25px;
}
.rules .rules-list li:after {
  display: block;
  position: absolute;
  left: 35px;
  top: 57px;
}
.rules .rules-list li.solve:after {
  content: url("/icons/magnify.svg");
  top: 64px;
  left: 37px;
}
.rules .rules-list li.give-up:after {
  content: url("/icons/sadface.svg");
  top: 64px;
  left: 37px;
}
.rules .rules-list li.hint:after {
  content: url("/icons/lightbulb.svg");
  left: 35px;
  top: 57px;
}
.rules .rules-list li.disrupt:after {
  content: url("/icons/disrupt.svg");
  top: 62px;
  left: 35px;
}
.rules .rules-list li.race:after {
  content: '';
  top: 64px;
  left: 37px;
  width: 40px;
  height: 32px;
  background: url("/icons/race.svg");
  background-size: contain;
}
.rules .rules-list li.objective:after {
  content: url("/icons/star.svg");
  top: 66px;
  left: 41px;
}
.rules .rules-list li:last-child {
  border: none;
}
.rules .rules-list li h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}
.rules.prelaunch .start-btn {
  display: none;
}
