.app-version {
  font-size: 12px;
  color: rgba(0,0,0,0.3);
  font-weight: 400;
  text-align: center;
}
drawer-menu {
  background: #fefefe;
  border-bottom: solid 1px #231f20;
  display: block;
  height: 50px;
  padding: 10px 20px 10px 10px;
  position: fixed;
  text-align: right;
  width: 100%;
  z-index: 3;
}
drawer-menu .menu-icon {
  cursor: pointer;
  padding: 0;
  background: none;
  margin-top: 4px;
}
drawer-content {
  background: #f2f1ef;
  display: none;
  position: absolute;
  text-align: left;
  top: 0;
  bottom: 0;
  height: 100%;
  right: 0;
  width: 250px;
  z-index: 3;
}
drawer-content .close-icon {
  cursor: pointer;
  padding: 0;
  background: none;
}
drawer-content.visible {
  display: block;
  border-left: solid 1px #231f20;
  max-height: 100%;
  overflow: auto;
}
drawer-content a {
  color: #231f20;
  transition: ease 1s all;
}
drawer-content a:hover {
  background: none;
  color: #16489b;
}
drawer-content button {
  color: #fff;
  margin: 0;
  padding: 0;
}
drawer-content .submit-section {
  margin: 85px 70px;
}
drawer-content .menu-section {
  margin: 0;
  padding: 0;
}
drawer-content .menu-section h3 {
  border-bottom: solid 1px #231f20;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 20px 20px;
  height: 70px;
}
drawer-content .menu-section ul {
  margin: 0;
  padding: 0;
}
drawer-content .menu-section .menu-item {
  background: #fefefe;
  border-bottom: solid 1px #231f20;
  display: block;
  padding: 20px;
  color: #231f20;
  list-style: none;
}
