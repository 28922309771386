.team-list {
  width: 100%;
  font-size: 14px;
}
.team-list a {
  color: #231f20;
}
.team-list .team {
  background: #fefefe;
  display: block;
  padding: 10px;
  border: solid 1px #231f20;
  margin-bottom: -1px;
}
.team-list .team.my-team {
  background: #e3c9de;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 10px;
}
.team-list .rank,
.team-list .name,
.team-list .points {
  display: inline-block;
  margin-right: 10px;
}
.team-list .rank {
  width: 40px;
  float: left;
}
.team-list .points {
  float: right;
}
.team-list .name {
  max-width: 135px;
  margin-right: 0;
}
