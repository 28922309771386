#app #page-content {
  transition: all 2s ease;
}
#app .disruptor {
  transition: all 2s ease;
  display: none;
}
#app.disrupted {
  transition: all 2s ease;
}
#app.disrupted #page-content {
  filter: blur(8px);
  animation: 0.1s blurry ease-out infinite alternate;
  transition: all 2s ease;
}
#app.disrupted .disruptor {
  background: rgba(0,0,0,0.7);
  color: #fefefe;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25% 20px 20px 20px;
  position: fixed;
  text-align: center;
  z-index: 999;
  transition: all 2s ease;
}
#app.disrupted .disruptor .disruptor-content {
  animation: 0.1s shake ease-out infinite alternate;
  text-align: center;
  width: 400px;
  max-width: 100%;
}
#app.disrupted .disruptor .disruptor-content h1,
#app.disrupted .disruptor .disruptor-content h2,
#app.disrupted .disruptor .disruptor-content h4 {
  color: #fefefe;
}
#app.disrupted .disruptor .disruptor-content h1 {
  font-size: 30px;
  font-weight: 400;
  margin: 0 0 10px 0;
}
#app.disrupted .disruptor .disruptor-content h2 {
  color: #e3c9de;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
}
#app.disrupted .disruptor .disruptor-content h4 {
  font-size: 18px;
  font-weight: 400;
  padding: 0 10%;
}
@-moz-keyframes blurry {
  from {
    transform: translateY(5px);
    margin-left: 5px;
  }
  to {
    transform: translateY(0px);
    margin-left: 0px;
  }
}
@-webkit-keyframes blurry {
  from {
    transform: translateY(5px);
    margin-left: 5px;
  }
  to {
    transform: translateY(0px);
    margin-left: 0px;
  }
}
@-o-keyframes blurry {
  from {
    transform: translateY(5px);
    margin-left: 5px;
  }
  to {
    transform: translateY(0px);
    margin-left: 0px;
  }
}
@keyframes blurry {
  from {
    transform: translateY(5px);
    margin-left: 5px;
  }
  to {
    transform: translateY(0px);
    margin-left: 0px;
  }
}
@-moz-keyframes shake {
  from {
    transform: translateY(0px);
    margin-left: 0;
  }
  to {
    transform: translateY(-5px);
    margin-left: 5px;
  }
}
@-webkit-keyframes shake {
  from {
    transform: translateY(0px);
    margin-left: 0;
  }
  to {
    transform: translateY(-5px);
    margin-left: 5px;
  }
}
@-o-keyframes shake {
  from {
    transform: translateY(0px);
    margin-left: 0;
  }
  to {
    transform: translateY(-5px);
    margin-left: 5px;
  }
}
@keyframes shake {
  from {
    transform: translateY(0px);
    margin-left: 0;
  }
  to {
    transform: translateY(-5px);
    margin-left: 5px;
  }
}
